import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`HTML ist von Haus aus barrierefrei. Zumindest wenn du native Elemente verwendest. Sehr oft implementieren
Entwickler:innen benutzerdefinierte UI-Komponenten für Formulare, um Design-Anforderungen zu erfüllen.
Dabei bleibt die Barrierefreiheit meist auf der Strecke.`}</p>
    <p>{`In diesem Artikel zeige ich dir, wie du native Formularelemente mit CSS individuell stylen kannst. Auf diese
Weise kannst du barrierefreie Web-Formulare mit einem konsistenten Look über alle Plattformen gestalten.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAf/EABQBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAdcyEaCcD//EABkQAQEAAwEAAAAAAAAAAAAAAAECAAMSFP/aAAgBAQABBQIL1nbM+mXKHjHXC//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPwFtf//EABYRAQEBAAAAAAAAAAAAAAAAAAAREv/aAAgBAgEBPwGVl//EAB4QAAICAAcAAAAAAAAAAAAAAAABEUECECExMlGB/9oACAEBAAY/Am7d7kqJ6K9ZpijLif/EABwQAAMAAQUAAAAAAAAAAAAAAAABESExQVFx4f/aAAgBAQABPyFdHGZmPBu0Jj2NskBrQEniE5pdQj//2gAMAwEAAgADAAAAEPc//8QAFhEBAQEAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/EBwl/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAERIVH/2gAIAQIBAT8QsljXT//EAB0QAQEAAgMAAwAAAAAAAAAAAAERADEhQWFRgZH/2gAIAQEAAT8QXbzpCF6dl8ym1I19L8XmHWFjCYkUfowsJxoGt2uAipLtGX8xKQvRn//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Pinsel und Malspachtel auf einer bunt bemalten Leinwand",
          "title": "Pinsel und Malspachtel auf einer bunt bemalten Leinwand",
          "src": "/static/04b9cc3391b17ea4910677c8e523c542/e5166/pexels-steve-johnson-paint-brush.jpg",
          "srcSet": ["/static/04b9cc3391b17ea4910677c8e523c542/f93b5/pexels-steve-johnson-paint-brush.jpg 300w", "/static/04b9cc3391b17ea4910677c8e523c542/b4294/pexels-steve-johnson-paint-brush.jpg 600w", "/static/04b9cc3391b17ea4910677c8e523c542/e5166/pexels-steve-johnson-paint-brush.jpg 1200w", "/static/04b9cc3391b17ea4910677c8e523c542/b17f8/pexels-steve-johnson-paint-brush.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © Steve Johnson / pexels.com`}</em></p>
    <h2>{`Wenn Design-Anforderungen die Barrierefreiheit zerstören`}</h2>
    <p>{`Native Formularelemente wie `}<InlineCode mdxType="InlineCode">{`input`}</InlineCode>{` oder `}<InlineCode mdxType="InlineCode">{`select`}</InlineCode>{` sind
grundsätzlich barrierefrei. Sie erhalten den Fokus, wenn Nutzer:innen die Tab-Taste drücken. Sie ermöglichen
die Interaktion mit der Enter- oder Leertaste, z.B. um eine Checkbox anzuhaken. Und sie kommunizieren ihre
Rolle und ihren aktuellen Status an Screenreader.`}</p>
    <p>{`Klingt super! Wer würde jemals auf die verrückte Idee kommen, statt der nativen Formularelemente eigene
UI-Komponenten mit nicht-semantischem Spaghetti-Code zu implementieren? Wie wir leider alle wissen,
sind benutzerdefinierte Formularelemente weit verbreitet. Meistens sind sie nicht barrierefrei. Sehen wir
uns folgendes Beispiel für eine selbstgebaute Auswahlliste an:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<div class="custom-select">
    <div class="select-trigger">
        <span>PlayStation 5</span>
        <div class="arrow"></div>
    </div>
    <div class="custom-options">
        <span class="option selected">
            PlayStation 5
        </span>
        <span class="option">Nintendo Switch</span>
        <span class="option">Xbox Series X</span>
    </div>
</div>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Dieser wilde Haufen von `}<InlineCode mdxType="InlineCode">{`div`}</InlineCode>{` und `}<InlineCode mdxType="InlineCode">{`span`}</InlineCode>{` Tags ist an sich
nicht barrierefrei für Tastatur- und Screenreader-Nutzer:innen. Natürlich lässt sich das mit ARIA-Attributen
und `}<span lang="en">{`Keydown Event Listeners`}</span>{` beheben. Doch vielen Entwickler:innen fehlt entweder dieses
Wissen oder es ist ihnen schlichtweg egal. Für sie zählt vor allem eines: Die Formularelemente entsprechend
der Design-Anforderungen der Kund:innen zu gestalten.`}</p>
    <h2>{`Warum das Styling von nativen Elementen herausfordernd sein kann`}</h2>
    <p>{`Das Styling von Formularelementen mit CSS war in der Vergangenheit oft schwierig. Browser wenden jeweils ihr
eigenes Standard-Styling an. Formularelemente unterscheiden sich stark darin, wie einfach ihr Aussehen mit CSS
angepasst werden kann. Glücklicherweise werden immer mehr veraltete Browser ausgemustert (Stichwort:
`}<a parentName="p" {...{
        "href": "https://death-to-ie11.com/"
      }}>{`Tod dem IE11`}</a>{`), weshalb man ohne Probleme auf neue Features moderner Browser
zurückgreifen kann.`}</p>
    <p>{`Mozilla hat einen tollen Artikel über das
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Learn/Forms/Styling_web_forms"
      }}>{`Styling von Web-Formularen`}</a>{`
verfasst, den ihr unbedingt lesen solltet. Sie unterteilen native Formularelemente in drei Gruppen: „Die Guten“,
„Die Bösen“ und „Das Hässliche“. Ich stimme großteils mit dem Artikel überein, mit Ausnahme der Einschätzung,
dass Checkboxen und Radio-Buttons schwer zu stylen wären. Das war vielleicht in der Vergangenheit so, trifft
aber heute nicht mehr zu. Mit modernem CSS sind der Fantasie keine Grenzen gesetzt.`}</p>
    <h2>{`Styling-Beispiel 1: Radio-Buttons`}</h2>
    <p>{`Um Radio-Buttons mit CSS umzugestalten, müsst ihr zuerst für die CSS-Eigenschaft `}<InlineCode mdxType="InlineCode">{`appearance`}</InlineCode>{`
den Wert `}<InlineCode mdxType="InlineCode">{`none`}</InlineCode>{` setzen. Damit entfernt ihr das Standard-Styling des Browsers bzw.
Betriebssystems. Nun könnt ihr euch kreativ austoben und sogar Zustands-Änderungen animieren:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    box-sizing: border-box;
    position: relative;
    width: 1.4em;
    height: 1.4em;
    border: 0.15rem solid darkred;
    border-radius: 50%;
}

input[type="radio"]::before {
    position: absolute;
    top: 50%;
    left: 50%;
    content: "";
    width: 0.7em;
    height: 0.7em;
    border-radius: 50%;
    background-color: darkred;
    /* scale(0) hides the dot in unchecked state */
    transform: translate(-50%, -50%) scale(0);
    transform-origin: center;
    transition: all 0.3s ease-in;
}

input[type="radio"]:checked::before {
    transform: translate(-50%, -50%) scale(1);
    transition: all 0.3s cubic-bezier(0.25, 0.25, 0.56, 2);
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`In meinem Beispiel werden Radio-Buttons im nicht ausgewählten Zustand als dunkelroter Kreis dargestellt.
Mithilfe des Pseudo-Elements `}<InlineCode mdxType="InlineCode">{`::before`}</InlineCode>{` und der Pseudo-Klasse `}<InlineCode mdxType="InlineCode">{`:checked`}</InlineCode>{`
wird ein dunkelroter Punkt angezeigt, wenn der Radio-Button ausgewählt wird. Probiere es selbst in meiner
`}<a parentName="p" {...{
        "href": "https://codepen.io/alexlehner86/pen/mdqGLxZ"
      }}>{`Demo auf Codepen`}</a>{` aus.`}</p>
    <h2>{`Styling-Beispiel 2: Select`}</h2>
    <p>{`Das HTML-Element `}<InlineCode mdxType="InlineCode">{`select`}</InlineCode>{` zählt zur Kategorie „Das Hässliche“. Die
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Learn/Forms/Advanced_form_styling#what_can_be_done_about_the_ugly_elements"
      }}>{`Formularelemente in dieser Gruppe`}</a>{`
sind problematisch, da sie von Browsern auf sehr unterschiedliche Weise dargestellt werden. Du kannst
sie zu einem gewissen Grad gestalten, doch einzelne Bestandteile entziehen sich völlig dem Zugriff von CSS.`}</p>
    <p>{`Mit der CSS-Eigenschaft `}<InlineCode mdxType="InlineCode">{`appearance`}</InlineCode>{` kannst du das Standard-Styling des `}<InlineCode mdxType="InlineCode">{`select`}</InlineCode>{`
Elements grundsätzlich überschreiben. Das hat jedoch keine Auswirkungen auf die Optionen-Liste, die beim Klick auf das
Element eingeblendet wird. Das Styling des HTML-Elements `}<InlineCode mdxType="InlineCode">{`option`}</InlineCode>{` mit CSS ist stark eingeschränkt
und wird nur von wenigen Browsern unterstützt.`}</p>
    <p>{`Meiner Meinung nach sind die Styling-Möglichkeiten aber ausreichend, um grundlegende Design-Anforderungen (Farbe, Schriftart
etc.) zu erfüllen. In meiner `}<a parentName="p" {...{
        "href": "https://codepen.io/alexlehner86/pen/mdqGLxZ"
      }}>{`Demo auf Codepen`}</a>{` habe ich folgendes Styling
angewandt:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`select {
    -webkit-appearance: none;
    appearance: none;
    box-sizing: border-box;
    background-color: white;
    background-image:
        url('/assets/custom_arrow_for_select.svg');
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    border: 0.25rem solid darkred;
    padding: 0.25em;
    /* Leave enough space for the arrow on the right */
    padding-right: 2em;
}`}</code>{`
        `}</deckgo-highlight-code>
    <h2>{`Natives HTML ist immer besser`}</h2>
    <p>{`Mit diesem Artikel wollte ich folgendes zeigen: Wenn du die Wahl zwischen nativem HTML und benutzerdefinierten
Formularelementen hast, dann entscheide dich für native HTML-Elemente. Sie sind von Haus aus barrierefrei und
können ausreichend mit CSS gestylt werden.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      